import { Action } from '@lib/plugin-redux-core';
import {
  CreateFolderUseCaseInput,
  CreateFormUseCaseInput,
  FolderSummary,
  FolderSummaryProps,
  FormSummary,
  Grade,
  SpaceObjects,
  Subject,
  UpdateFolderUseCaseInput,
  UpdateFormUseCaseInput,
  ParentFolder,
  FormSummaryProps,
  ImportFormUseCaseInput,
  FormStatus,
  FormStatusCheck,
} from '@module/form';

import { SpacePageState, SpacePageStatus } from './space.reducer';

const mapStateToSpaceObjects = (
  forms: FormSummary[],
  folders: FolderSummary[],
  parentFolders: ParentFolder[],
): SpaceObjects => {
  return SpaceObjects.create({
    forms,
    folders,
    parentFolders,
  }).getValue();
};

const addNewFolderToSpaceObjects = (
  spaceObjects: SpaceObjects,
  newFolderSummary: FolderSummary,
): SpaceObjects => {
  const { forms, folders, parentFolders } = spaceObjects;
  const changedFolderSummaries: FolderSummary[] = [...folders, newFolderSummary];
  return mapStateToSpaceObjects(forms, changedFolderSummaries, parentFolders);
};

const updateFolderFromSpacePage = (
  spaceObjects: SpaceObjects,
  updatedFolderSummary: UpdateFolderUseCaseInput,
): SpaceObjects => {
  const { forms, folders, parentFolders } = spaceObjects;
  const newFoldersSummaries: FolderSummary[] = folders.map((folder: FolderSummary) => {
    if (folder.id.toString() === updatedFolderSummary.id.toString()) {
      const { input } = updatedFolderSummary;
      if (input.title) {
        folder.updateTitle(input.title);
        folder.updatedAt = Date.now().toString();
      }
    }
    return folder;
  });
  return mapStateToSpaceObjects(forms, newFoldersSummaries, parentFolders);
};

const deleteFolderFromSpaceObjects = (spaceObjects: SpaceObjects, folderId: string) => {
  const { forms, folders, parentFolders } = spaceObjects;
  const changedFolderSummaries: FolderSummary[] = folders.filter((folder: FolderSummary) => {
    const isNotDeletedFolder = folder.id.toString() !== folderId;
    const isNotSubFolderOfDeletedFolder = folder.parentId !== folderId;

    return isNotDeletedFolder && isNotSubFolderOfDeletedFolder;
  });

  const changedFormSummaries: FormSummary[] = forms.filter((form: FormSummary) => {
    const isNotSubFormOfDeletedFolder = form.folderId !== folderId;

    return isNotSubFormOfDeletedFolder;
  });

  return mapStateToSpaceObjects(changedFormSummaries, changedFolderSummaries, parentFolders);
};

const addNewFormToSpaceObjects = (
  spaceObjects: SpaceObjects,
  newFormSummary: FormSummary,
): SpaceObjects => {
  const { forms, folders, parentFolders } = spaceObjects;
  const changedFormSummaries: FormSummary[] = [...forms, newFormSummary];
  return mapStateToSpaceObjects(changedFormSummaries, folders, parentFolders);
};

const updateFormFromSpaceObjects = (
  spaceObjects: SpaceObjects,
  useCaseInput: UpdateFormUseCaseInput,
): SpaceObjects => {
  const { forms, folders, parentFolders } = spaceObjects;

  const newFormSummaries: FormSummary[] = forms.map((form: FormSummary) => {
    if (form.id.toString() === useCaseInput.id) {
      const { input } = useCaseInput;
      const { title, status } = input;
      if (title) {
        form.updateTitle(input.title);
        form.updatedAt = Date.now().toString();
      }
      if (status) {
        form.updateStatus(status);
      }
    }

    return form;
  });

  return mapStateToSpaceObjects(newFormSummaries, folders, parentFolders);
};

const updateFormsFromSpaceObjects = (
  spaceObjects: SpaceObjects,
  useCaseInputs: UpdateFormUseCaseInput[],
): SpaceObjects => {
  const { forms, folders, parentFolders } = spaceObjects;

  const newFormSummaries: FormSummary[] = forms.map((form: FormSummary) => {
    const foundForm = useCaseInputs.find((input) => input.id === form.id.toString());
    if (foundForm) {
      const { input } = foundForm;
      const { title, status } = input;
      if (title) {
        form.updateTitle(input.title);
        form.updatedAt = Date.now().toString();
      }
      if (status) {
        form.updateStatus(status);
      }
    }

    return form;
  });

  return mapStateToSpaceObjects(newFormSummaries, folders, parentFolders);
};

const deleteFormFromSpaceObjects = (spaceObjects: SpaceObjects, formId: string): SpaceObjects => {
  const { forms, folders, parentFolders } = spaceObjects;
  const changedFormSummaries: FormSummary[] = forms.filter(
    (form: FormSummary) => form.id.toString() !== formId,
  );
  return mapStateToSpaceObjects(changedFormSummaries, folders, parentFolders);
};

export const handleSearchSpaceObjectsUseCase = {
  executing: (state: SpacePageState): SpacePageState => {
    return {
      ...state,
      searchFormsAndFoldersStatus: SpacePageStatus.EXECUTE,
    };
  },
  success: (state: SpacePageState, action: Action): SpacePageState => {
    const { payload } = action;

    return {
      ...state,
      formsAndFoldersSearchOptions: payload as SpaceObjects,
      searchFormsAndFoldersStatus: SpacePageStatus.SUCCESS,
    };
  },
  error: (state: SpacePageState, action: Action): SpacePageState => {
    const { error } = action;
    return {
      ...state,
      searchFormsAndFoldersStatus: SpacePageStatus.ERROR,
      error: error,
    };
  },
  reset: (state: SpacePageState) => {
    return { ...state };
  },
};

export const handleGetFolderByIdUseCase = {
  executing: (state: SpacePageState): SpacePageState => {
    return {
      ...state,
      getFolderByIdStatus: SpacePageStatus.EXECUTE,
    };
  },
  success: (state: SpacePageState, action: Action): SpacePageState => {
    const spaceObjects = action.payload as SpaceObjects;
    const { forms } = spaceObjects;
    const importingFormIds = forms
      .filter((form) => form.status === FormStatus.Processing)
      .map((form) => form.id.toString());

    return {
      ...state,
      spaceObjects,
      getFolderByIdStatus: SpacePageStatus.SUCCESS,
      importingFormIds,
    };
  },
  error: (state: SpacePageState, action: Action): SpacePageState => {
    const { error } = action;
    return {
      ...state,
      getFolderByIdStatus: SpacePageStatus.ERROR,
      error: error,
    };
  },
  reset: (state: SpacePageState) => {
    return { ...state };
  },
};

export const handleGetGradesAndSubjectsUseCase = {
  executing: (state: SpacePageState): SpacePageState => {
    return {
      ...state,
    };
  },
  success: (state: SpacePageState, action: Action): SpacePageState => {
    const { payload } = action;

    return {
      ...state,
      gradesAndSubjects: payload as [Grade[], Subject[]],
    };
  },
  error: (state: SpacePageState, action: Action): SpacePageState => {
    const { error } = action;
    return { ...state, error: error };
  },
};

export const handleCreateFolderUseCase = {
  executing: (state: SpacePageState, action: Action): SpacePageState => {
    const input = action.payload as CreateFolderUseCaseInput;

    const createdFolderSummaryProps: FolderSummaryProps = {
      ...input,
    };
    const createdFolderSummary = FolderSummary.create(createdFolderSummaryProps).getValue();

    return {
      ...state,
      createdFolderSummary: createdFolderSummary,
      createFolderStatus: SpacePageStatus.EXECUTE,
    };
  },
  success: (state: SpacePageState): SpacePageState => {
    const spaceObjects = addNewFolderToSpaceObjects(state.spaceObjects, state.createdFolderSummary);
    return {
      ...state,
      spaceObjects,
      createFolderStatus: SpacePageStatus.SUCCESS,
    };
  },
  error: (state: SpacePageState, action: Action): SpacePageState => {
    const { error } = action;

    return {
      ...state,
      error: error,
      createFolderStatus: SpacePageStatus.ERROR,
    };
  },
  reset: (state: SpacePageState): SpacePageState => {
    return {
      ...state,
      createFolderStatus: SpacePageStatus.RESET,
    };
  },
};

export const handleUpdateFolderUseCase = {
  executing: (state: SpacePageState, action: Action): SpacePageState => {
    const { payload } = action;
    return {
      ...state,
      updatedFolderSummary: payload as UpdateFolderUseCaseInput,
      updateFolderSummaryStatus: SpacePageStatus.EXECUTE,
    };
  },
  success: (state: SpacePageState): SpacePageState => {
    const spaceObjects: SpaceObjects = updateFolderFromSpacePage(
      state.spaceObjects,
      state.updatedFolderSummary,
    );

    return {
      ...state,
      spaceObjects,
      updateFolderSummaryStatus: SpacePageStatus.SUCCESS,
    };
  },
  error: (state: SpacePageState, action: Action): SpacePageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      updateFolderSummaryStatus: SpacePageStatus.ERROR,
    };
  },
  reset: (state: SpacePageState): SpacePageState => {
    return {
      ...state,
      updateFolderSummaryStatus: SpacePageStatus.RESET,
    };
  },
};

export const handleDeleteFolderUseCase = {
  executing: (state: SpacePageState, action: Action): SpacePageState => {
    const { payload } = action;

    return {
      ...state,
      deletedFolderId: payload as string,
      deleteFolderStatus: SpacePageStatus.EXECUTE,
    };
  },
  success: (state: SpacePageState): SpacePageState => {
    const spaceObjects = deleteFolderFromSpaceObjects(
      state.spaceObjects,
      state.deletedFolderId as string,
    );
    return {
      ...state,
      spaceObjects,
      deleteFolderStatus: SpacePageStatus.SUCCESS,
    };
  },
  error: (state: SpacePageState, action: Action): SpacePageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      deleteFolderStatus: SpacePageStatus.ERROR,
    };
  },
  reset: (state: any) => {
    return {
      ...state,
      deleteFolderStatus: SpacePageStatus.RESET,
    };
  },
};

export const handleCreateFormUseCase = {
  executing: (state: SpacePageState, action: Action): SpacePageState => {
    const input = action.payload as CreateFormUseCaseInput;

    const formSummaryProps: FormSummaryProps = {
      id: input.id,
      folderId: input.folderId,
      title: input.title,
      updatedAt: Date.now().toString(),
      status: FormStatus.Active,
    };
    const createdFormSummary = FormSummary.create(formSummaryProps).getValue();

    return {
      ...state,
      newFormId: input.id,
      createdFormSummary,
      createFormStatus: SpacePageStatus.EXECUTE,
    };
  },
  success: (state: SpacePageState): SpacePageState => {
    const spaceObjects = addNewFormToSpaceObjects(state.spaceObjects, state.createdFormSummary);

    return {
      ...state,
      spaceObjects,
      createFormStatus: SpacePageStatus.SUCCESS,
    };
  },
  error: (state: SpacePageState, action: Action): SpacePageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      createFormStatus: SpacePageStatus.ERROR,
    };
  },
  reset: (state: SpacePageState): SpacePageState => {
    return {
      ...state,
      createFormStatus: SpacePageStatus.RESET,
    };
  },
};

export const handleImportFormUseCase = {
  executing: (state: SpacePageState, action: Action): SpacePageState => {
    const input = action.payload as ImportFormUseCaseInput;
    const importingFormId = input.id;

    const formSummaryProps: FormSummaryProps = {
      id: input.id,
      folderId: input.folderId,
      title: input.title,
      updatedAt: Date.now().toString(),
      status: FormStatus.Processing,
    };
    const importedFormSummary = FormSummary.create(formSummaryProps).getValue();

    return {
      ...state,
      importedFormSummary,
      importFormStatus: SpacePageStatus.EXECUTE,
      importingFormId,
    };
  },
  success: (state: SpacePageState): SpacePageState => {
    const spaceObjects = addNewFormToSpaceObjects(state.spaceObjects, state.importedFormSummary);
    const importingFormIds = [...state.importingFormIds, state.importingFormId];

    return {
      ...state,
      spaceObjects,
      importFormStatus: SpacePageStatus.SUCCESS,
      importingFormIds,
      importingFormId: null,
    };
  },
  error: (state: SpacePageState, action: Action): SpacePageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      importFormStatus: SpacePageStatus.ERROR,
      importedFormSummary: null,
    };
  },
  reset: (state: SpacePageState): SpacePageState => {
    return {
      ...state,
      importFormStatus: SpacePageStatus.RESET,
      importedFormSummary: null,
      importingFormId: null,
    };
  },
};

export const handleUpdateFormTitleUseCase = {
  executing: (state: SpacePageState, action: Action): SpacePageState => {
    return {
      ...state,
      updatedFormSummary: action.payload as UpdateFormUseCaseInput,
      updateFormSummaryStatus: SpacePageStatus.EXECUTE,
    };
  },
  success: (state: SpacePageState): SpacePageState => {
    const spaceObjects = updateFormFromSpaceObjects(state.spaceObjects, state.updatedFormSummary);
    return {
      ...state,
      spaceObjects,
      updateFormSummaryStatus: SpacePageStatus.SUCCESS,
    };
  },
  error: (state: SpacePageState, action: Action): SpacePageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      updateFormSummaryStatus: SpacePageStatus.ERROR,
    };
  },
  reset: (state: SpacePageState): SpacePageState => {
    return {
      ...state,
      updateFormSummaryStatus: SpacePageStatus.RESET,
    };
  },
};

export const handleDeleteFormUseCase = {
  executing: (state: SpacePageState, action: Action): SpacePageState => {
    const { payload } = action;

    return {
      ...state,
      deletedFormId: payload as string,
      deleteFormStatus: SpacePageStatus.EXECUTE,
    };
  },
  success: (state: SpacePageState): SpacePageState => {
    const spaceObjects = deleteFormFromSpaceObjects(
      state.spaceObjects,
      state.deletedFormId as string,
    );

    return {
      ...state,
      spaceObjects,
      deleteFormStatus: SpacePageStatus.SUCCESS,
    };
  },
  error: (state: SpacePageState, action: Action): SpacePageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      deleteFormStatus: SpacePageStatus.ERROR,
    };
  },
  reset: (state: any) => {
    return {
      ...state,
      deleteFormStatus: SpacePageStatus.RESET,
    };
  },
};

export const handleFindFormByIdsUseCase = {
  executing: (state: SpacePageState): SpacePageState => {
    return {
      ...state,
      findFormByIdsStatus: SpacePageStatus.EXECUTE,
    };
  },
  success: (state: SpacePageState, action: Action): SpacePageState => {
    const formStatusCheckList = action.payload as FormStatusCheck[];
    let spaceObjects = state.spaceObjects;

    const importingFormIds = formStatusCheckList
      .filter((form) => form.status === FormStatus.Processing)
      .map((form) => form.id.toString());
    const hasErrorForm = formStatusCheckList.some((form) => form.status === FormStatus.Error);

    const finishedImportForms = formStatusCheckList.filter(
      (form) => form.status !== FormStatus.Processing,
    );
    if (finishedImportForms.length > 0) {
      const updateInputs: UpdateFormUseCaseInput[] = finishedImportForms.map((form) => {
        return {
          id: form.id.toString(),
          input: {
            status: form.status,
          },
        };
      });
      spaceObjects = updateFormsFromSpaceObjects(state.spaceObjects, updateInputs);
    }

    return {
      ...state,
      findFormByIdsStatus: SpacePageStatus.SUCCESS,
      spaceObjects,
      importingFormIds,
      hasErrorForm,
    };
  },
  error: (state: SpacePageState, action: Action): SpacePageState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      findFormByIdsStatus: SpacePageStatus.ERROR,
    };
  },
  reset: (state: SpacePageState) => {
    return {
      ...state,
      findFormByIdsStatus: SpacePageStatus.RESET,
      hasErrorForm: false,
    };
  },
};
