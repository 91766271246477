import { useEffect, useState } from 'react';

function calculateMaxLength(size) {
  var screenWidth = window.innerWidth;
  var maxLength = Math.floor(screenWidth / size) - Math.floor(screenWidth / (size + 10));
  return maxLength;
}

export function useMaxLengthText(size) {
  var _useState = useState(calculateMaxLength(size)),
      maxLength = _useState[0],
      setMaxLength = _useState[1];

  useEffect(function () {
    var handleResize = function handleResize() {
      setMaxLength(calculateMaxLength(size));
    };

    window.addEventListener('resize', handleResize);
    return function () {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return maxLength;
}