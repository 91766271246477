import { combineReducers } from 'redux';
import { pageLayoutReducer, PageLayoutState } from './layout';
import { authReducer } from './auth';
import { assignmentReducer, AssignmentState } from './assignment';
import { submissionReducer } from './submission';
import { sidebarReducer } from './sidebar';
import { FormPreviewPageState, formPreviewReducer } from './form-preview';
import { spaceObjectsReducer, SpacePageState } from './space';
import { AssignmentReviewPageState, assignmentReviewReducer } from './assignment-review';
import { toastReducer, ToastState } from './toast';
import { SubmissionPreviewPageState, submissionPreviewReducer } from './submission-preview';
import { reportReducer, ReportState } from './report';
import { gradingSubmissionReducer } from './grading-submission';
import { FormEditPageState, formEditReducer } from './form-edit';
import { GradingSubmissionState } from './grading-submission/grading-submission.states';

export type RootReducerState = {
  //Todo: Refactor type for each reducer has any type
  auth: any;
  pageLayout: PageLayoutState;
  toast: ToastState;
  assignment: AssignmentState;
  submission: any;
  sidebar: any;
  spaceObjects: SpacePageState;
  formPreview: FormPreviewPageState;
  submissionPreviewPage: SubmissionPreviewPageState;
  assignmentReview: AssignmentReviewPageState;
  report: ReportState;
  gradingSubmission: GradingSubmissionState;
  formEdit: FormEditPageState;
};

export const rootReducer = combineReducers({
  auth: authReducer,
  pageLayout: pageLayoutReducer,
  toast: toastReducer,
  assignment: assignmentReducer,
  spaceObjects: spaceObjectsReducer,
  submission: submissionReducer,
  sidebar: sidebarReducer,
  formPreview: formPreviewReducer,
  submissionPreviewPage: submissionPreviewReducer,
  assignmentReview: assignmentReviewReducer,
  report: reportReducer,
  gradingSubmission: gradingSubmissionReducer,
  formEdit: formEditReducer,
});
